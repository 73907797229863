<template>
  <div id="services">
    <div class="inner-banner">
      <div class="overlay">
	<div class="container clearfix">
	  <h2>Services</h2>
	  <ul>
	    <li>
	      <router-link to="/" class="nav-ink"> Home </router-link>
	    </li>
	    <li>/</li>
	    <li>Services</li>
	  </ul>
	</div> <!-- /.container -->
      </div> <!-- /.overlay -->
    </div> <!-- /.inner-banner -->

    
    <div class="portfolio-details">
      <div class="container">

	<div class="details-text" style="padding-top: 0px !important;">
	  <div class="row">
	    <div class="col-lg-6 col-md-6 col-12 text-left-side">
	      <h2>Product Market Fit</h2>
	      <p style="margin-top:30px;">
		As you reach each new  customer stage: from your
		passionate early users, to early adopters, to the
		initial wave the needs; the messages and requirements on
		your product and  organization change.
	      </p>
	      <p>

	      Company's often struggle to map the product, support
		wrapper, pricing, positioning, and messaging to the
	      right customers at each stage of market engagement.
	      </p>

	      <p>
	      I have led this process for multiple start-ups
	      and business divisions. I can work with you, your
	      sales team, and your product team to sharpen the
	      definition of the offer, tighten execution loops, and
	      create flexibility where you need it.
	      </p>
	      <p>The result is a happier more scalable organization,
	      happier customers, better sales execution, and higher
	      profitability.</p> 
	    </div> <!-- /.text-left-side -->
	    <div class="col-lg-6 col-md-6 col-sm-8 col-12">
	      <img src="images/ser1.jpg" alt="">
	    </div> <!-- /.portfolio-info-list -->
	  </div> <!-- /.row -->
	</div> <!-- /.details-text -->
      </div> <!-- /.container -->
    </div>
    
    
    
    <div class="portfolio-details">
      <div class="container">

	<div class="details-text">
	  <div class="row">
	    <div class="col-lg-6 col-md-6 col-12 text-left-side">
	      <h2>Product Development</h2>
	      <p style="margin-top:30px;">For the non-technical founder
	      the selection, architecture, and development of a product
	      can be daunting. In my experience, its
	      relatively easy to build a first prototype of your
	      concept, but building a complete product that's meets
	      stringent quality, performance, uptime, and user
	      experience goals is much harder and making the
	      wrong choices in the beginning can sink the whole
		company. </p>
	      <p> With over 30 years of technology experience, I have
	      designed dozens of software and hardware systems that
	      have delivered a compelling user experience at
	      scale.</p> 
	    </div> <!-- /.text-left-side -->
	    <div class="col-lg-6 col-md-6 col-sm-8 col-12">
	      <img src="images/s2.jpg" alt="">
	    </div> <!-- /.portfolio-info-list -->
	    
	    <div style="clear:both;"></div>
	  </div> <!-- /.row -->
	</div> <!-- /.details-text -->
      </div> <!-- /.container -->
    </div>
    
    
    <div class="portfolio-details" style="background:#f6f6f6 !important;">
      <div class="container">

	<div class="details-text">
	  <div class="row">
	    <div class="col-lg-6 col-md-6 col-12 text-left-side">
	      <h2>Operational Process Improvement</h2>
	      <p style="margin-top:30px;">As start-ups transition from
	      seed to early growth stage, many
	      start-ups hit process and communications issues. The
	      transformation from a team, where everyone was
	      communicating directly, to one of written, process
	      driveng communication takes time, training, and
	      potentially new management.</p> 
	      <p>I have taken multiple technology and product teams
	      through this transformation. The teams end up with
	      better morale, deliver better results, and 
		accountability.</p> 
	    </div> <!-- /.text-left-side -->
	    <div class="col-lg-6 col-md-6 col-sm-8 col-12">
	      <img src="images/s3.jpg" alt="">
	    </div> <!-- /.portfolio-info-list -->
	  </div> <!-- /.row -->
	</div> <!-- /.details-text -->
      </div> <!-- /.container -->
    </div>


  </div>
</template>

<script>
export default {
name: 'services',
    title: 'Services - Ten Factor Growth',
    components: {
    },
    methods: {
    }
}
</script>

<style scoped>

</style>

