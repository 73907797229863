<template>
  <div id="Home">
    <MainBanner />
    <OurServices />
    <GrowingStartup />
    <IndustryExperience />
    <EngageVentureFirms />
  </div>
</template>

<script>

import MainBanner from '@/views/partials/MainBanner';
import OurServices from '@/views/partials/OurServices';
import GrowingStartup from '@/views/partials/GrowingStartup';
import IndustryExperience from '@/views/partials/IndustryExperience';
import EngageVentureFirms from '@/views/partials/EngageVentureFirms';

export default {
    name: 'Home',
    title: 'Home - Ten Factor Growth',
    components: {
	MainBanner, OurServices, GrowingStartup,
	IndustryExperience
    },
    mounted() {
	this.$analytics.logEvent("Home");
    }
}
</script>

<style scoped>
</style>
