<template>
	<div class="portfolio-details" style="background: #f6f6f6 !important;">
	  <div class="container">

	    <div class="details-text">
	      <div class="row">
		<div class="col-lg-6 col-md-6 col-12 text-left-side">
		  <h2>Industry Experience</h2>
		  <p style="margin-top:30px;">I  have worked with
		    companies in marketing tech, health tech,
		    education tech, robotics, consumer hardware, and
		    enterprise. I can advise you on positioning and alignment for a successful exit.  </p> 
		</div> <!-- /.text-left-side -->
		<div class="col-lg-6 col-md-6 col-sm-8 col-12">
		  <img class="home-img" src="images/stock-photo-data-theme.jpg" alt="">
		</div> <!-- /.portfolio-info-list -->
	      </div> <!-- /.row -->
	    </div> <!-- /.details-text -->
	  </div> <!-- /.container -->
	</div>
</template>

<script>
export default {
    name: 'IndustryExperience',
    components: {
    },
    methods: {
    }
}
</script>

<style scoped>
.home-img {
  width: 80%;
  height: auto;
  }
</style>

