<template>
  <div class="our-service">
    <div class="container">
      <div class="theme-title text-center">
	<h2>Services</h2>
      </div> <!-- /.theme-title -->
      <div class="row">
	<div class="col-lg-4 col-6">
	  <div class="single-service">
	    <router-link to="/services" class="nav-ink"> 
	      <div class="image-box"><img src="images/s1.jpg" alt=""></div>
	      </router-link>
	    <div class="text">
	      <h4>
		  <router-link to="/services" class="nav-ink"> Technology & Data</router-link>
	      </h4>
	      <p>Comnpanies data and technology and data strategies evolve as they grow. I can help you get the right level in place for each stage.</p>
	    </div> <!-- /.text -->
	  </div> <!-- /.single-service -->
	</div> <!-- /.col- -->
	<div class="col-lg-4 col-6">
	  <div class="single-service">
	    <router-link to="/services" class="nav-ink"> 
	      <div class="image-box"><img src="images/s2.jpg" alt=""></div>
	      </router-link>
	    <div class="text">
	      
	      <h4>
		<router-link to="/services" class="nav-ink"> Product Development</router-link>
	      </h4>
	      <p>I have helped dozens of companies define the core of their initial product, test the
		concept, and refine their go-to-market.</p>
	    </div> <!-- /.text -->
	  </div> <!-- /.single-service -->
	</div> <!-- /.col- -->
	<div class="col-lg-4 col-6">
	  <div class="single-service">
	    <router-link to="/services" class="nav-ink"> 
	      <div class="image-box"><img src="images/s3.jpg" alt=""></div>
	    </router-link>
	    <div class="text">
	      <h4>
		<router-link to="/services" class="nav-ink"> Operational Execution</router-link>
	      </h4>
	      <p>Hitting speed bumps in your technology or product? I can help align your product definition and development process. 
	      </p>
	    </div> <!-- /.text -->
	  </div> <!-- /.single-service -->
	</div> <!-- /.col- -->

      </div> <!-- /.row -->
    </div> <!-- /.container -->
  </div> <!-- /.our-service -->
</template>

<script>
export default {
    name: 'OurServices',
    components: {
    },
    methods: {
    }
}
</script>

<style scoped>

</style>

