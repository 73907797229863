<template>
  <!-- Slider main container -->
  <div id="theme-main-banner" class="banner-one camera_wrap">
    <swiper ref="mainSwiper" :options="mainSwiperOptions">
      <swiper-slide style="background:url('images/slide1.jpg')">
	<div class="container" style ="width: 80%" id="carousel-data">
	  <h1>Technology and Data Strategy</h1>
	  <p> Evolving your technology and data stack to meet the needs of a
	  growing company is a complex. I have helped dozens of companies
	    navigate these complex issues.  </p>

	  <router-link to="/" class="button-one"> Learn More</router-link>
	  <router-link to="/contact" class="button-two"> Get In Touch</router-link>
	</div>
	<div class="swiper-button-next" v-on:click="nextSlide"> </div>
      </swiper-slide>

      <swiper-slide style="background:url('images/slide2.jpg')">
	<div class="container" style ="width: 80%" id="carousel-data">
	  <h1>Product Development</h1>
	  <p> Product development not hiting goals?  Product and execs not
	  getting features they need?  </p>
	  <p>I have helped dozens of companies strengthen their
	    development to refine their product market fit.</p> 
	  <router-link to="/" class="button-one"> Learn More</router-link>
	  <router-link to="/contact" class="button-two"> Get In Touch</router-link>
	</div>
	<div class="swiper-button-prev" v-on:click="prevSlide"> </div>
	<div class="swiper-button-next" v-on:click="nextSlide"> </div>
      </swiper-slide>

      <swiper-slide style="background:url('images/slide3.jpg')">
	<div class="container" style ="width: 80%" id="carousel-data">
	  <h1>Operational Execution</h1>
	  <p>Hitting speed bumps in your technology or product
	    organization? Running into problems scaling your
	    technology or getting to many consumer complaints? </p>
	  <p>
	    I can help you with these common growth stage issues to  put the right
	    people, processes, and controls in place to manage growth. </p>
	  <router-link to="/" class="button-one"> Learn More</router-link>
	  <router-link to="/contact" class="button-two"> Get In Touch</router-link>
	</div>
	<div class="swiper-button-prev" v-on:click="prevSlide"> </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'

// import style (>= Swiper 6.x)
import 'swiper/swiper-bundle.css'

export default {
    name: 'MainBanner',
    data() {
	return {
	    mainSwiperOptions: {
		navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev'
		}
	    },
	}
    },
    computed: {
	swiper() {
	    return this.$refs.mainSwiper.$swiper;
	}
    },
    mounted(){
	console.log('Current swiper',this.swiper);
    },
    components: {
	Swiper,
	SwiperSlide
    },
    methods: {
	prevSlide: function(event) {
	    console.log('prevSlide');
	    this.swiper.slidePrev();
	},
	nextSlide: function(event) {
	    console.log('nextSlide');
	    this.swiper.slideNext();
	}
    }
}  

</script>

<style scoped>
.banner-one {
    display: block;
    height: 600px;
}

/*---------------------------- Theme Main Banner -------------------------*/
.camera_wrap {float:none;}
#theme-main-banner .camera_overlayer {
  content: '';
  position: absolute;
  top:0;
  left:0;
  right: 0;
  bottom: 0;
  background: transparent;
}
#theme-main-banner.banner-two .camera_overlayer {background: rgba(0,73,164,0.9);}
#theme-main-banner .main-container {position: relative;}
#theme-main-banner .camera_caption {height: 100%;}
#theme-main-banner .container {
  position: relative;
  z-index: 1;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
#theme-main-banner .camera_caption {
  background: transparent;
  position: absolute;
  width: 100%;
  height: 100%;
  top:0;
  text-shadow: none;
  left: 0;
  padding: 0;
}
#theme-main-banner .camera_prev,#theme-main-banner .camera_next  {background: transparent;}
#theme-main-banner .camera_prev span,#theme-main-banner .camera_next span {display: none;}
#theme-main-banner .camera_prev:before,#theme-main-banner .camera_next:before {
  font-family: 'font-awesome';
  position: absolute;
  line-height: 40px;
  width: 100%;
  font-size: 35px;
  text-align: center;
}
#theme-main-banner .camera_prev:before {content: '';}
#theme-main-banner .camera_next:before {content: '';}
#theme-main-banner .camera_caption p {
  font-size: 22px;
  line-height: 35px;
  color: rgba(0,0,0,0.6);
  padding: 25px 0 30px 0;
}

#theme-main-banner .camera_caption a {
  line-height: 51px;
  border-width: 2px;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  color: #ffffff;
  background: #231f20;
  padding: 0 30px;
  margin-top: 15px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

/*Swiper*/
.swiper-container {
    width: 100%;
    height: 80%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}
.main-slider .slider-bg-position {
    background-size: cover!important;
    background-position: center center!important;
}
.main-slider .swiper-button-prev, .main-slider .swiper-button-next {
    background-image: none!important;
    color: #fff;
    width: 50px;
    height: 50px;
    border: 0px solid #fff;
    text-align: center;
    line-height: 50px;
    font-size: 20px;
}
.container h1 {
  font-size: 5vw;
}
.container p {
    font-size: 2vw;
}
.container router-link {
    font-size: 4vw;
}
.main-slider h2 {
    color: #fff;
    font-size: 3vw;
    line-height: 59px;
    /* padding: 0 12%; */
    /* text-transform: uppercase; */
  text-align: left;
}
.main-slider .swiper-pagination-bullet {
	width: 20px;
	height: 20px;
	background: rgba(255,255,255,0.9)
}
.main-slider .swiper-pagination-bullet-active {
	background: #0297ff
}

#carousel-data .button-one{
    line-height: 51px;
    width: auto;
    border-width: 2px;
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    color: #ffffff;
    background: #1455a6;
    padding: 0 40px;
    margin-top: 15px;
    margin-right: 20px;
    margin-bottom: 10px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

#carousel-data a.button-one:hover{
  line-height: 51px;
  border-width: 2px;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  color: #ffffff;
  background: #ffcb4c;
  padding: 0 40px;
  margin-top: 15px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#carousel-data a.button-two{
  line-height: 51px;
  border-width: 2px;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  color: #ffffff;
  background: #231f20;
  padding: 0 40px;
  margin-top: 15px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#carousel-data a.button-two:hover{
  line-height: 51px;
  border-width: 2px;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  color: #ffffff;
  background: #ffcb4c;
  padding: 0 40px;
  margin-top: 15px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

</style>

