<template>
	<div class="portfolio-details">
	  <div class="container">

	    <div class="details-text">
	      <div class="row">
		<div class="col-lg-6 col-md-6 col-12 text-left-side">
		  <h2>Engaging Venture Firms</h2>
		  <p style="margin-top:30px;">We work with a wide range of top venture firms to advise, manage
		    companies in their portfolios, and assisted start-up on fund raising.</p>
		  <p>We work with only companies we believe in. We will take equity as compensation and invest
		    in the equity when its appropriate.</p>
		</div> <!-- /.text-left-side -->
		<div class="col-lg-6 col-md-6 col-sm-8 col-12">
		  <!--		  <img src="images/ser3.jpeg" alt=""> -->
		  <img class="home-img" src="images/stock-photo-funding.jpg" alt="">
		</div> <!-- /.portfolio-info-list -->
	      </div> <!-- /.row -->
	    </div> <!-- /.details-text -->
	  </div> <!-- /.container -->
	</div>

</template>

<script>
export default {
    name: 'GrowingStartup',
    components: {
    },
    methods: {
    }
}
</script>
<style scoped>
  .home-img{
  width: 80%;
  height: auto;
  }
</style>

