<template>
	<div class="portfolio-details">
	  <div class="container">

	    <div class="details-text">
	      <div class="row">
		<div class="col-lg-6 col-md-6 col-12 text-left-side">
		  <h2>Growing Your Startup</h2>
		  <p style="margin-top:30px;">You have a passion to
		    drive your company to the next level - but you
		    need executive management and advice on key
		    decisions. I have been there.</p>
		  
		  <p>I have decades or start-up experience over
		    several dozen startups in all top executive roles:
		    CEO, COO, and CTO. I can fill in your gaps,
		    delivery on key initiatives, and
		    help build the team.</p>
		</div> <!-- /.text-left-side -->
		<div class="col-lg-6 col-md-6 col-sm-8 col-12">
		  <img class="home-img" src="images/ser1.jpg" alt="">
		</div> <!-- /.portfolio-info-list -->
	      </div> <!-- /.row -->
	    </div> <!-- /.details-text -->
	  </div> <!-- /.container -->
	</div>

</template>

<script>
export default {
    name: 'GrowingStartup',
    components: {
    },
    methods: {
    }
}
</script>

<style scoped>
.home-img {
  width: 80%;
  height: auto;
  }

</style>

